import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';

export default {
    name: "Footprint",
    data() {
    return {
      type_build: [
        { id: 0, name: 'อาคารสำนักงาน' },
        { id: 1, name: 'ที่พักอาศัย' },
        { id: 2, name: 'แผนที่ เอจีซี' },
      ],
      group_footprint: [],
      group_footprint_mobile: [],
      image: null,
      list_footprint: {},
      baseFront: this.$baseFront
    }
  },
    components: {
        Footer, Header, Breadcrumb, Toolbar
    },
    async mounted() {
        await this.fetchFootPrint()
        await this.fetchGroupFootPrint()
        document.title = "AGC - Footprint";
        AOS.init();
    },
    methods: {
        find_footprint(id) {
            return this.list_footprint.find(x => x.id == id)
        },
        gotoDetail(id){
            this.$router.push(`footprint-detail/${id}`)
        },
        async fetchFootPrint() {
            await this.$axios.get("/api/show/footprint")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.list_footprint = response.data.data
                    }
                })
        },
        async fetchGroupFootPrint() {
            await this.$axios.get("/api/show/group_footprint")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.group_footprint = response.data.data
                        this.group_footprint.forEach(x => {
                            if(x.type_build == 0) {
                                if(x.ref_footprint_image_1) {
                                    this.group_footprint_mobile.push({
                                        type_build: x.type_build,
                                        type_row: 1,
                                        ref_footprint_image_1: x.ref_footprint_image_1,
                                        ref_footprint_image_2: null,
                                    })
                                }
                                if(x.ref_footprint_image_2 || x.ref_footprint_image_3) {
                                    this.group_footprint_mobile.push({
                                        type_build: x.type_build,
                                        type_row: 2,
                                        ref_footprint_image_1: x.ref_footprint_image_2,
                                        ref_footprint_image_2: x.ref_footprint_image_3,
                                    })
                                }
                                if(x.ref_footprint_image_4) {
                                    this.group_footprint_mobile.push({
                                        type_build: x.type_build,
                                        type_row: 1,
                                        ref_footprint_image_1: x.ref_footprint_image_4,
                                        ref_footprint_image_2: null,
                                    })
                                }
                            } else {
                                if(x.ref_footprint_image_1 || x.ref_footprint_image_2) {
                                    this.group_footprint_mobile.push({
                                        type_build: x.type_build,
                                        type_row: 2,
                                        ref_footprint_image_1: x.ref_footprint_image_1,
                                        ref_footprint_image_2: x.ref_footprint_image_2,
                                    })
                                }
                                if(x.ref_footprint_image_3) {
                                    this.group_footprint_mobile.push({
                                        type_build: x.type_build,
                                        type_row: 1,
                                        ref_footprint_image_1: x.ref_footprint_image_3,
                                        ref_footprint_image_2: null,
                                    })
                                }
                                if(x.ref_footprint_image_4 || x.ref_footprint_image_5) {
                                    this.group_footprint_mobile.push({
                                        type_build: x.type_build,
                                        type_row: 2,
                                        ref_footprint_image_1: x.ref_footprint_image_4,
                                        ref_footprint_image_2: x.ref_footprint_image_5,
                                    })
                                }
                            }
                        });
                    }
                })
        },
    },
};